import Backdrop from "@mui/material/Backdrop";
import React from "react";
import { Typography, Stack, Box, Button, Alert, AlertTitle } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";

export function DeleteDialog(props) {
	return (
		<Backdrop
			key="Delete"
			sx={{ color: "#fff", zIndex: "5000" }}
			open={props.dialogOpen}
			onClick={() => {
				props.cancelFunction();
			}}>
			<Box
				className="text-center"
				onClick={(e) => e.stopPropagation()}>
				<Stack className="text-center">
					<Alert
						severity="error"
						className="p-3">
						<AlertTitle>Warning!</AlertTitle>
						<Typography>You're about to delete:</Typography>
						<Typography
							variant="h5"
							className="mt-3 mb-3">
							{props.entryName}
						</Typography>
						<Typography>Are you sure?</Typography>

						<div
							style={{ display: "flex", justifyContent: "center" }}
							className="w-100">
							<Button
								className="m-3"
								variant="contained"
								color="primary"
								style={{ flexGrow: 1 }}
								onClick={props.cancelFunction}>
								<ArrowBackIosIcon />
								No
							</Button>
							<Button
								className="m-3"
								variant="contained"
								color="error"
								style={{ flexGrow: 1 }}
								onClick={props.confirmFunction}>
								<DeleteIcon className="mr-3" />
								Yes
							</Button>
						</div>
					</Alert>
				</Stack>
			</Box>
		</Backdrop>
	);
}
