import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BalanceIcon from "@mui/icons-material/Balance";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentsIcon from "@mui/icons-material/Payments";
import SettingsIcon from "@mui/icons-material/Settings";
import HomeIcon from "@mui/icons-material/Home";
import React, { useEffect, useState } from "react";

const IconDict = {
	"/home": (
		<HomeIcon
			fontSize="inherit"
			color="inherit"
		/>
	),
	"/balance": (
		<BalanceIcon
			fontSize="inherit"
			color="inherit"
		/>
	),
	"/expenses": (
		<PaymentsIcon
			fontSize="inherit"
			color="inherit"
		/>
	),
	"/payments": (
		<ReceiptIcon
			fontSize="inherit"
			color="inherit"
		/>
	),
	"/settings": (
		<SettingsIcon
			fontSize="inherit"
			color="inherit"
		/>
	),
};

export function MenuIcon({ linktarget, active }) {
	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(linktarget === active);

	useEffect(() => {
		setCurrentPage(linktarget === active);
	}, [active, linktarget]);

	function handleClick() {
		navigate(linktarget);
	}
	return (
		<Typography
			sx={{
				borderRadius: 0,
				flexGrow: 1,
				color: currentPage ? "primary.dark" : "primary.background",
				bgcolor: currentPage ? "primary.background" : "primary.dark",
				textAlign: "center",
				fontSize: "2rem",
			}}
			onClick={handleClick}>
			{IconDict[linktarget]}
		</Typography>
	);
}
