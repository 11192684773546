import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { HomePage } from "./pages/Home.page";
import { BalancePage } from "./pages/Balance.page";
import { ExpensesPage } from "./pages/Expenses.page";
import { PaymentsPage } from "./pages/Payments.page";
import { MenuComponent } from "./components/Menu.comp";
import { SnackbarProvider } from "notistack";
import { SettingsPage } from "./pages/Settings.page";
import { CssBaseline } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { EditPaymentPage } from "./pages/SinglePayment.page";

const theme = createTheme({
	palette: {
		primary: {
			main: "#4caf50",
			light: "#6fbf73",
			dark: "#357a38",
			background: "#e8f5e9",
		},
		secondary: {
			main: "#ffc400",
			light: "#ffcf33",
			dark: "#b28900",
		},
		PeopleSelector: {
			main: "#b28900",
		},
		background: {
			default: "#e8f5e9",
		},
	},
});

export default function App() {
	return (
		<BrowserRouter>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<MenuComponent key="MenuComponent" />
				<SnackbarProvider>
					<AnimatePresence>
						<Routes>
							<Route
								path="/"
								element={<Navigate to="/home" />}
							/>
							<Route
								path="/home"
								element={<HomePage />}
							/>
							<Route
								path="/balance"
								element={<BalancePage />}
							/>
							<Route
								path="/addpayment"
								element={<EditPaymentPage />}
							/>
							<Route
								path="/editpayment/:paymentIdParam"
								element={<EditPaymentPage />}
							/>
							<Route
								path="/expenses"
								element={<ExpensesPage />}
							/>
							<Route
								path="/payments"
								element={<PaymentsPage />}
							/>
							<Route
								path="/settings"
								element={<SettingsPage />}
							/>
						</Routes>
					</AnimatePresence>
				</SnackbarProvider>
			</ThemeProvider>
		</BrowserRouter>
	);
}
