import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { MenuIcon } from "./MenuIcon.comp";
import { useLocation } from "react-router-dom";

const LinkList = ["/home", "/balance", "/expenses", "/payments", "/settings"];

export function MenuComponent() {
	const location = useLocation();
	const [active, setActive] = useState(location); // State to track active state

	useEffect(() => {
		// Update active state when location.pathname changes
		setActive(location.pathname);
	}, [location]);

	return (
		<Stack
			sx={{ display: "flex", bgcolor: "primary.dark" }}
			direction="row">
			{LinkList?.map((link) => (
				<MenuIcon
					linktarget={link}
					key={link + Math.random()}
					active={active}
				/>
			))}
		</Stack>
	);
}
