import {
	Box,
	Paper,
	TextField,
	Grid,
	Button,
	Typography,
	InputAdornment,
	Stack,
	ToggleButtonGroup,
	ToggleButton,
	Container,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "react-responsive";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { DeleteDialog } from "../dialogs/Delete.dialog";
import { PeopleSelector } from "../components/PeopleSelector.comp";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { limitDecimalPlaces } from "../helpers/LimitDecimals.helper";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import { useSnackbar } from "notistack";
import { Loader } from "../dialogs/Loader.dialog";

export function EditPaymentPage() {
	const { paymentIdParam } = useParams();
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const storageUser = localStorage.getItem("defaultUser");
	const [paymentName, setPaymentName] = useState("");
	const storageInput = localStorage.getItem("defaultInput");
	const [inputCurrency, setInputCurrency] = useState(storageInput || "dollar");
	const [paymentAmount, setPaymentAmount] = useState("");
	const [paymentGoal, setPaymentGoal] = useState("");
	const [paymentSource, setPaymentSource] = useState(storageUser || null);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [paymentEuro, setPaymentEuro] = useState("");
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [selectedPayment, setSelectedPayment] = useState();
	const navigate = useNavigate();
	const [nameError, setNameError] = useState("");
	const [amountError, setAmountError] = useState("");

	function handleInputCurrency(e) {
		if (e.target.value && e.target.value !== inputCurrency) {
			setInputCurrency(e.target.value);
		}
	}
	useEffect(() => {
		if (paymentIdParam) {
			axios
				.get(`/api/payment/get/specific/${paymentIdParam}`)
				.then((response) => {
					setSelectedPayment(response.data);
				})
				.catch((error) => {
					console.error(error);
				});
		} else if (window.location.pathname.includes("addpayment")) {
			setLoading(false);
		}
	}, [paymentIdParam]);

	const resetValues = useCallback(() => {
		setPaymentName("");
		setPaymentAmount("");
		setPaymentGoal("");
		setPaymentSource(storageUser || null);
		setAmountError("");
		setNameError("");
	}, [storageUser]);

	useEffect(() => {
		if (selectedPayment) {
			setPaymentName(selectedPayment?.PaymentDescription);
			setPaymentAmount(limitDecimalPlaces(selectedPayment?.PaymentAmount));
			setPaymentSource(selectedPayment?.PaymentSource);
			setPaymentGoal(selectedPayment?.PaymentGoal);
			setPaymentEuro(limitDecimalPlaces(selectedPayment?.PaymentAmount * 0.92));
			setLoading(false);
		}
	}, [selectedPayment, resetValues]);

	function handlePaymentGoal(event, newType) {
		if (newType) {
			setPaymentGoal(newType);
		}
	}

	function handleSubmit() {
		const reqBody = {
			PaymentDescription: paymentName,
			PaymentSource: paymentSource,
			PaymentAmount: paymentAmount,
			PaymentGoal: paymentGoal,
		};
		submitChanges(reqBody);
	}

	function deleteFunction() {
		deletePayment();
		setDeleteDialog(false);
	}

	function cancelFunction() {
		setDeleteDialog(false);
	}

	function handlePaymentSource(e) {
		if (e.target.value) {
			setPaymentSource(e.target.value);
		}
	}

	function handlePaymentChange(e) {
		setAmountError("");
		let inputValue = e.target.value;
		if (inputValue === "") {
			setPaymentAmount("");
			setPaymentEuro("");
			return;
		}
		if (inputValue) {
			inputValue = parseFloat(String(inputValue).replace(",", "."));
			if (inputCurrency === "euro") {
				setPaymentAmount(inputValue / 0.92);
				setPaymentEuro(inputValue);
				return;
			} else {
				setPaymentAmount(inputValue);
				setPaymentEuro(inputValue * 0.92);
				return;
			}
		}
	}

	function submitChanges(reqBody) {
		if (reqBody.PaymentGoal.length < 1) {
			enqueueSnackbar("Please select who the payment is for.", {
				variant: "error",
			});
			return null;
		}
		if (reqBody.PaymentSource.length < 1) {
			enqueueSnackbar("Please select who paid for this.", {
				variant: "error",
			});
			return null;
		}
		if (selectedPayment) {
			setLoading(true);
			axios
				.post("/api/payment/edit/" + selectedPayment._id, reqBody)
				.then((response) => {
					if (response.status === 200) {
						navigate("/home");

						enqueueSnackbar(`Edited payment '${selectedPayment.PaymentDescription}'.`, {
							variant: "success",
						});
					}
				})
				.catch((error) => {
					setLoading(false);
					parseErrors(error.response.data.errors);
					enqueueSnackbar(
						error.response.status === 400
							? error.response.data.errors[Object.keys(error.response.data.errors)[0]].message
							: "Error.",
						{
							variant: "error",
						}
					);
				});
		} else {
			axios
				.post("/api/payment/create", reqBody)
				.then((response) => {
					if (response.status === 200) {
						navigate("/home");
						enqueueSnackbar(`Added payment '${reqBody.PaymentDescription}'.`, {
							variant: "success",
						});
					}
				})
				.catch((error) => {
					setLoading(false);
					parseErrors(error.response.data.errors);

					enqueueSnackbar(
						error.response.status === 400
							? error.response.data.errors[Object.keys(error.response.data.errors)[0]].message
							: "Error.",
						{
							variant: "error",
						}
					);
				});
		}
	}

	function parseErrors(errors) {
		if (errors?.PaymentAmount) {
			setAmountError(errors?.PaymentAmount?.message);
		}
		if (errors?.PaymentDescription) {
			setNameError(errors?.PaymentAmount?.message);
		}
	}

	function handleDescriptionChange(e) {
		setNameError("");
		setPaymentName(e.target.value);
	}

	function deletePayment() {
		setLoading.apply(true);
		axios
			.delete("/api/payment/delete/" + selectedPayment._id)
			.then((response) => {
				if (response.status === 200) {
					navigate("/home");
					enqueueSnackbar(`Deleted payment ${selectedPayment.PaymentDescription}.`, {
						variant: "success",
					});
				}
			})
			.catch((error) => {
				setLoading(false);

				enqueueSnackbar(
					error.response.status === 400
						? error.response.data.errors[Object.keys(error.response.data.errors)[0]].message
						: "Error occurred.",
					{
						variant: "error",
					}
				);
			});
	}

	return (
		<>
			<DeleteDialog
				entryName={paymentName}
				dialogOpen={deleteDialog}
				cancelFunction={cancelFunction}
				confirmFunction={deleteFunction}
			/>
			<Loader loading={loading} />

			{!loading && (
				<Container
					sx={{ mt: 3 }}
					key="EditPaymentPage"
					initial={{ y: 50, zIndex: 0, opacity: 0 }}
					animate={{ y: 0, zIndex: 1, opacity: 1 }}
					exit={{ y: 50, zIndex: 0, opacity: 0 }}
					component={motion.div}>
					<Paper onClick={(e) => e.stopPropagation()}>
						<Box className="p-3">
							<Typography variant="h4">
								{selectedPayment ? "Edit payment" : "Add a new payment"}
							</Typography>
							<Grid
								container
								spacing={2}
								className="mt-2">
								<Grid
									item
									xs={12}>
									<Typography
										variant="h5"
										className="mb-2">
										How much
									</Typography>
									<Stack
										spacing={2}
										direction="row">
										<TextField
											error={amountError !== ""}
											helperText={amountError !== "" ? amountError : ""}
											fullWidth
											value={inputCurrency === "euro" ? paymentEuro : paymentAmount}
											onChange={handlePaymentChange}
											id="paymentAmount"
											label="Amount"
											type="number"
											InputLabelProps={{
												shrink: true,
											}}
											InputProps={{
												inputMode: "numeric",
												pattern: "[0-9]*",
												startAdornment: (
													<InputAdornment position="start">
														{inputCurrency === "euro" ? "€" : "$"}
													</InputAdornment>
												),
											}}
											variant="outlined"
										/>
										<ToggleButtonGroup
											color="PeopleSelector"
											exclusive
											onChange={handleInputCurrency}
											value={inputCurrency}
											fullWidth>
											<ToggleButton
												onClick={() => {
													setInputCurrency("dollar");
												}}
												value="dollar">
												<AttachMoneyIcon />
											</ToggleButton>
											<ToggleButton
												onClick={() => {
													setInputCurrency("euro");
												}}
												value="euro">
												<EuroIcon />
											</ToggleButton>
										</ToggleButtonGroup>
									</Stack>
								</Grid>
								<Grid
									item
									xs={12}>
									<Typography
										variant="h5"
										className="mb-2">
										What for
									</Typography>

									<TextField
										error={nameError !== ""}
										helperText={nameError !== "" ? nameError : ""}
										value={paymentName}
										onChange={handleDescriptionChange}
										id="paymentName"
										label="Description"
										variant="outlined"
										sx={{ width: "100%" }}
									/>
								</Grid>

								<Grid
									item
									xs={12}>
									<Typography
										variant="h5"
										className="mb-2">
										Paid By
									</Typography>
									<PeopleSelector
										selector={paymentSource}
										handleSelect={handlePaymentSource}
									/>
								</Grid>
								<Grid
									item
									xs={12}>
									<Typography variant="h5">Paid For</Typography>
									<PeopleSelector
										unique={false}
										selector={paymentGoal}
										handleSelect={handlePaymentGoal}
									/>
								</Grid>
								{paymentGoal?.length > 0 && paymentAmount && (
									<Grid
										item
										xs={12}>
										<Typography
											variant="subtitle2"
											color="gray">
											Which comes down to {inputCurrency === "euro" ? "€ " : "$ "}
											{limitDecimalPlaces(
												inputCurrency === "euro"
													? paymentEuro / paymentGoal?.length
													: paymentAmount / paymentGoal?.length
											)}{" "}
											per couple
										</Typography>
									</Grid>
								)}

								<Grid
									item
									className="text-left"
									xs={4}>
									<Button
										variant="contained"
										onClick={() => {
											navigate(-1);
										}}
										sx={{ bgcolor: "secondary.main" }}>
										<ArrowBackIosIcon className={isPortrait ? "ml-3 mr-3" : "mr-3"} />
										{isPortrait ? "" : "back"}
									</Button>
								</Grid>
								<Grid
									item
									xs={4}
									className="text-center">
									{selectedPayment ? (
										<Button
											variant="contained"
											color="error"
											onClick={() => {
												setDeleteDialog(true);
											}}>
											<DeleteIcon className={isPortrait ? "ml-3 mr-3" : "mr-3"} />
											{isPortrait ? "" : "Delete"}
										</Button>
									) : (
										""
									)}
								</Grid>
								<Grid
									item
									xs={4}
									className="GridButtonContainer text-right">
									<Button
										variant="contained"
										color="success"
										onClick={handleSubmit}>
										{selectedPayment ? (
											<>
												<CheckIcon className={isPortrait ? "ml-3 mr-3" : "mr-3"} />{" "}
												{!isPortrait ? "Edit" : ""}
											</>
										) : (
											<>
												<AddOutlinedIcon className={isPortrait ? "ml-3 mr-3" : "mr-3"} />{" "}
												{!isPortrait ? "Add" : ""}
											</>
										)}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Paper>
				</Container>
			)}
		</>
	);
}
