import {
	Container,
	Paper,
	Grid,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Button,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BalanceInfo } from "../dialogs/BalanceInfo.dialog";
import { useMediaQuery } from "react-responsive";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Loader } from "../dialogs/Loader.dialog";
import { NameBlock } from "../components/NameBlock.comp";
import { PrintMoney } from "../helpers/PrintMoney.helper";
import { motion } from "framer-motion";
import { NameDict } from "../helpers/NameDict.helper";
import { useNavigate } from "react-router-dom";

export function BalancePage() {
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const [priceList, setPriceList] = useState();
	const [balanceHelpOpen, setBalanceHelpOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/api/payment/get/all")
			.then((response) => {
				setPriceList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (priceList) {
			setLoading(false);
		}
	}, [priceList]);

	return (
		<>
			<Loader loading={loading} />

			<BalanceInfo
				helpOpen={balanceHelpOpen}
				closeHelp={() => {
					setBalanceHelpOpen(false);
				}}
			/>
			{priceList ? (
				<Container
					key="BalancePage"
					initial={{ y: 50, zIndex: 0, opacity: 0 }}
					animate={{ y: 0, zIndex: 1, opacity: 1 }}
					exit={{ y: 50, zIndex: 0, opacity: 0 }}
					component={motion.div}>
					<Paper style={{ marginTop: "1em", marginBottom: "2em", padding: "1em" }}>
						<Grid container>
							<Grid
								className="mt-2"
								item
								xs={12}>
								<Typography
									variant="h3"
									className="clickableObject"
									onClick={() => {
										setBalanceHelpOpen(true);
									}}>
									Balance {isPortrait ? "" : "Sheet"} <InfoOutlinedIcon />
								</Typography>
								<Typography color="gray">See what's been paid for</Typography>
							</Grid>
							<Grid
								className="mt-2"
								item
								xs={12}>
								<Button
									onClick={() => {
										navigate("/addpayment");
									}}
									variant="contained"
									color="secondary"
									fullWidth>
									<AddOutlinedIcon />
									Add
								</Button>
							</Grid>
							<Grid
								style={{ marginTop: "1em" }}
								item
								xs={12}>
								{priceList && (
									<>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="h5">Cost:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">Activity:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">By:</Typography>
													</TableCell>
													{isPortrait ? (
														""
													) : (
														<TableCell>
															<Typography variant="h5">Participants:</Typography>
														</TableCell>
													)}
												</TableRow>
											</TableHead>
											<TableBody>
												{priceList.map((element) => {
													return (
														<TableRow
															key={element._id}
															onClick={() => {
																navigate(`/editpayment/${element._id}`);
															}}
															className="clickableObject">
															<TableCell>{PrintMoney(-element.PaymentAmount)}</TableCell>
															<TableCell>{element.PaymentDescription}</TableCell>

															<TableCell>
																{isPortrait ? (
																	<NameBlock
																		nameInput={NameDict[element.PaymentSource]}
																	/>
																) : (
																	NameDict[element.PaymentSource]
																)}
															</TableCell>
															{isPortrait ? null : (
																<TableCell>
																	{element.PaymentGoal.map((element) => {
																		return NameDict[element];
																	}).join(", ")}
																</TableCell>
															)}
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Container>
			) : (
				""
			)}
		</>
	);
}
