import { Container, Paper, Grid, Table, TableRow, TableCell, TableBody, TableHead, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../dialogs/Loader.dialog";
import { NameBlock } from "../components/NameBlock.comp";
import { PeopleSelector } from "../components/PeopleSelector.comp";
import { PrintMoney } from "../helpers/PrintMoney.helper";
import { motion } from "framer-motion";
import { NameDict } from "../helpers/NameDict.helper";
import { useNavigate } from "react-router-dom";

export function ExpensesPage() {
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const [priceList, setPriceList] = useState();
	const storageSelector = localStorage.getItem("defaultUser");
	const [selector, setSelector] = useState(storageSelector || "all");
	const [owings, setOwings] = useState();
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	useEffect(() => {
		axios
			.get("/api/payment/get/all")
			.then((response) => {
				setPriceList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (priceList) {
			const owings = { all: 0, ac: 0, db: 0, pj: 0 };
			priceList.forEach((priceElement) => {
				Object.keys(owings).forEach((element) => {
					if (priceElement.PaymentGoal.includes(element)) {
						owings[element] += priceElement.PaymentAmount / priceElement.PaymentGoal.length;
					} else if (element === "all") {
						owings["all"] += priceElement.PaymentAmount;
					}
				});
			});

			setOwings(owings);
			setLoading(false);
		}
	}, [priceList]);

	function handleSelect(e) {
		if (e.target.value) {
			if (e.target.value === selector) {
				setSelector("all");
			} else {
				setSelector(e.target.value);
			}
		}
	}

	return (
		<>
			<Loader loading={loading} />
			{!loading ? (
				<Container
					key="ExpensesPage"
					initial={{ y: 50, zIndex: 0, opacity: 0 }}
					animate={{ y: 0, zIndex: 1, opacity: 1 }}
					exit={{ y: 50, zIndex: 0, opacity: 0 }}
					component={motion.div}>
					<Paper style={{ marginTop: "1em", marginBottom: "2em", padding: "1em" }}>
						<Grid container>
							<Grid
								className="mt-2 text-center"
								item
								xs={12}>
								<Typography variant="h3">Expenses {isPortrait ? "for" : "made for"}</Typography>
							</Grid>

							<Grid
								className="mt-2"
								item
								xs={12}>
								<PeopleSelector
									selector={selector}
									handleSelect={handleSelect}
								/>
							</Grid>
						</Grid>
					</Paper>
					<Paper style={{ marginTop: "1em", marginBottom: "2em", padding: "1em" }}>
						<Grid
							className="mt-2"
							item
							xs={12}>
							<Grid
								className="mt-2 text-center"
								item
								xs={12}>
								<Typography variant="h4">{NameDict[selector]}</Typography>
							</Grid>
							<Grid
								className="mt-2 text-center"
								item
								xs={12}>
								<Typography variant="h4">
									{selector !== "all" ? "Expenses" : "is"}: {PrintMoney(-owings[selector])}
								</Typography>
							</Grid>
							<Grid
								style={{ marginTop: "1em" }}
								item
								xs={12}>
								{!loading && (
									<>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="h5">Cost:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">Activity:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">By:</Typography>
													</TableCell>
													{isPortrait ? (
														""
													) : (
														<TableCell>
															<Typography variant="h5">Participants:</Typography>
														</TableCell>
													)}
												</TableRow>
											</TableHead>
											<TableBody>
												{priceList.map((element) => {
													if (selector !== "all" && !element.PaymentGoal.includes(selector)) {
														return null;
													}

													const division =
														selector === "all" ? 1 : element.PaymentGoal.length;
													return (
														<TableRow
															key={element._id}
															className="clickableObject"
															onClick={() => {
																navigate(`/editpayment/${element._id}`);
															}}>
															<TableCell>
																{PrintMoney(-element.PaymentAmount / division)}
															</TableCell>
															<TableCell>{element.PaymentDescription}</TableCell>

															<TableCell>
																{isPortrait ? (
																	<NameBlock
																		nameInput={NameDict[element.PaymentSource]}
																	/>
																) : (
																	NameDict[element.PaymentSource]
																)}
															</TableCell>
															{isPortrait ? (
																""
															) : (
																<TableCell>
																	{element.PaymentGoal.map((element) => {
																		return NameDict[element];
																	}).join(", ")}
																</TableCell>
															)}
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
									</>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Container>
			) : (
				""
			)}
		</>
	);
}
