import { Backdrop, Box, Grid, Typography, CircularProgress } from "@mui/material";

export function Loader({ loading }) {
	return (
		<Backdrop
			key="Loader"
			invisible={true}
			open={loading}
			sx={{ zIndex: "9000" }}>
			<Box className="p-3">
				<Grid
					container
					spacing={2}
					className="mt-2">
					<Grid
						item
						xs={12}
						style={{ textAlign: "center" }}>
						<Typography variant="h3">Loading...</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ textAlign: "center" }}>
						<CircularProgress size="5em" />
					</Grid>
				</Grid>
			</Box>
		</Backdrop>
	);
}
