import { Typography } from "@mui/material";

const stringToColor = (str) => {
	const sortCharactersInString = (str) => [...str].sort((a, b) => a.localeCompare(b)).join("");
	str = sortCharactersInString(str);
	let hash = 0;
	str.split("").forEach((char) => {
		hash = char.charCodeAt(0) + ((hash << 5) - hash);
	});
	let colour = "#";
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		colour += value.toString(16).padStart(2, "0");
	}
	return colour;
};

export function NameBlock({ nameInput }) {
    const shortName = `${nameInput.split(" ")[0][0]}${nameInput.split(" ")[1][0]}${nameInput.split(" ")[2][0]}`;
    return <Typography variant='h6' sx={{
        bgcolor: stringToColor(nameInput),
        color: "white",
        textAlign: 'center',
        borderRadius:1
    }}>{shortName}</Typography>;
}
