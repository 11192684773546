import { Backdrop, Box, Paper, Grid, Button, Typography } from "@mui/material";

export function BalanceInfo(props) {
	return (
		<Backdrop
			key="BalanceInfo"
			open={props.helpOpen}
			onClick={props.closeHelp}
			sx={{ color: "#fff", zIndex: "4000" }}>
			<Paper>
				<Box className="p-3">
					<Grid
						container
						spacing={2}
						className="mt-2">
						<Grid
							item
							xs={12}>
							<Typography variant="h4">Balance - explained</Typography>
						</Grid>
						<Grid
							item
							xs={12}>
							<Typography>
								Here you can enter expenses that you paid for, especially when you have paid for a
								larger expense for multiple people.
							</Typography>
							<Typography className="mt-3">
								Enter the complete, full amount any payment or expense costs. It will be automatically
								divided.
							</Typography>
							<Typography className="mt-3">
								Use the 'Description' box to help identify it in the future.
							</Typography>
							<Typography className="mt-3">
								The 'Paid by' field describes who made the payment, which is probably you.
							</Typography>
							<Typography className="mt-3">
								Select who was part of the activity that you paid for in the 'Paid for' field.
							</Typography>
							<Typography className="mt-3">
								Clicking on an existing entry will allow you to view, and edit that specific entry.
							</Typography>
						</Grid>
					</Grid>
					<Button
						className="w-100 mt-4"
						color="success"
						onClick={props.closeHelp}
						variant="contained">
						GOT IT
					</Button>
				</Box>
			</Paper>
		</Backdrop>
	);
}
