import { Backdrop, Box, Paper, Grid, Button, Typography } from "@mui/material";

export function SummaryInfo(props) {
	return (
		<Backdrop
			key="SummaryInfo"
			open={props.helpOpen}
			onClick={props.closeHelp}
			sx={{ color: "#fff", zIndex: "4000" }}>
			<Paper>
				<Box className="p-3">
					<Grid
						container
						spacing={2}
						className="mt-2">
						<Grid
							item
							xs={12}>
							<Typography variant="h4">Summary - explained</Typography>
						</Grid>
						<Grid
							item
							xs={12}>
							<Typography>
								The summary shows an overview of who owes other people money, and who should receive
								money from others.
							</Typography>
							<Typography
								className="mt-3"
								style={{ color: "#28a745" }}>
								Green text means they paid more then what they should have, and that to make things
								even, they should receive money from others.
							</Typography>
							<Typography
								className="mt-3"
								style={{ color: "#dc3545" }}>
								Red text means they paid less then what they should have, and that to make things even,
								they should send money to others.
							</Typography>
						</Grid>
					</Grid>
					<Button
						className="w-100 mt-4"
						color="success"
						onClick={props.closeHelp}
						variant="contained">
						GOT IT
					</Button>
				</Box>
			</Paper>
		</Backdrop>
	);
}
