export function limitDecimalPlaces(number, maxDecimalPlaces = 2) {
	if (!number) return;
	// Convert the number to a string with a fixed number of decimal places
	const fixedNumber = number.toFixed(maxDecimalPlaces);

	// Parse the string back to a floating-point number
	const result = parseFloat(fixedNumber);

	return result;
}
